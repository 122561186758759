import React, { useState } from "react";
import { Link, animateScroll as scroll } from "react-scroll";
import { FiChevronDown } from "react-icons/fi";
const ScrollTop = () => {
  const [Change, setColorchange] = useState(false);
  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);

  return (
    <div className={Change ? "header ds-1 ":"d-ss"}
   
    >
      <div className="scroll-top"
      
      >
        <div
        
          className={Change ?  "metaportal_fn_totop":"d-1p" }
          style={{ height: "183px" }}
        >
          <span 
          
          className="totop_inner" onClick={() => scroll.scrollToTop()}>
            <span 
            className="icon">
              <FiChevronDown className="fn__svg" />
            </span>
            <span 
          
            className="text">Scroll To Top</span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ScrollTop;
