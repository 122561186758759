import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import toast, { Toaster } from 'react-hot-toast';
const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_rnbigel",
        "template_13srowj",
        form.current,
        "5ERO2rD1qezXjNQD_"
      )
      .then(
        (result) => {
          console.log(result.text);
          toast.success('Successfully Submit')
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <section id="contact">
      <div className="container">
        <h3
          className="fn__maintitle big"
          data-text="Contact Us"
          data-align="center"
          data-aos="zoom-in"
          data-aos-duration="1000"
        >
          Contact Us
        </h3>
        <div className="fn_cs_contact_info">
          <ul>
            <li className="contact-logo"
                   data-aos-duration="1000"
            
                   data-aos="fade-right"
            >
              <div className="item">
                <img
                  src={require("../../img/logo.png")}
                  alt=""
                  style={{ width: "300px" }}
                />
              </div>
            </li>
            <li
            data-aos="zoom-in"
            data-aos-duration="1000"

            >
              <div className="item">
                <h4 className="label">Phone</h4>
                <h3>
                  <a href="tel:+917973525377">+91 7973525377</a>
                </h3>
                <h4 className="lable">Email</h4>
                <h3>
                  <a href="mailto:frenifyteam@gmail.com">
                    contact@xenolearning.com
                  </a>
                </h3>
              </div>
            </li>
            <li
                   data-aos-duration="1000"
            
                   data-aos="fade-left"
            >
              <div className="item">
                <h4 className="label">Office</h4>
                <h3> Delta Infox Head Office Chandigarh</h3>
              </div>
            </li>
          </ul>
        </div>
        <div className="fn_cs_contact_form">
          <form
             action="/"
             method="post"
             className="contact_form"
             id="contact_form"
             autoComplete="off"
            ref={form}
            onSubmit={sendEmail}
          >
            <div className="input_list">
              <ul>
                <li>
                  <input
                    name="name"
                    id="name"
                    type="text"
                    placeholder="Your Name *"
                  />
                </li>
                <li>
                  <input
                    name="email"
                    id="email"
                    type="text"
                    placeholder="Your Email *"
                  />
                </li>
                <li>
                  <input
                    name="contact-no"
                    id="tel"
                    type="text"
                    placeholder="Your Phone *"
                  />
                </li>
                <li>
                  <input
                    name="topic"
                    id="subject"
                    type="text"
                    placeholder="Topic *"
                  />
                </li>
                <li className="full">
                  <textarea
                    name="message"
                    id="message"
                    placeholder="Your Message *"
                    defaultValue={""}
                  />
                </li>
                <li className="full"
                             data-aos="fade-right"

                  data-aos-easing="linear"
                  data-aos-duration="1000"
                >
                  <div className="mw300">
                    <button
                      id="send_message"
                      className="metaportal_fn_button full bb-none"
                    >
                      <span>Send Message</span>
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </form>

          <div
            className="returnmessage"
            data-success="Your message has been received, We will contact you soon."
          />
          <div className="empty_notice">
            <span>! Please Fill Required Fields !</span>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Contact;
