import { useState } from "react";

const Faq = () => {
  const faqs1 = [
    {
      title: "What is XENO LEARNING?",
      dec: "Xeno Learning is an online coaching service that offers coding courses and coaching to help students learn to code and improve their programming skills.",
    },
    {
      title: "What coding languages and technologies are taught at Xeno Learning?",
      dec: "Xeno Learning offers coaching and courses in a variety of coding languages and technologies, including HTML, CSS, JavaScript, Python, Java, and more.",
    },
    {
      title: "HOW DO I STAY UPDATED AND SAFE?",
      dec: "A handcrafted collection of 10,000 characters developed by artist Frenify. In its purest form, RENGA is art and the art of storytelling.",
    },
    {
      title: "How does the online coaching service work?",
      dec: "Xeno Learning's online coaching service provides students with personalized coaching and feedback on their coding projects and assignments through a virtual learning environment. Students can also access course materials and resources, and participate in online discussions and forums to interact with other students and instructors.",
    },
  ];
  const faqs2 = [
    {
      title: "What are the benefits of using Xeno Learning's online coaching service?",
      dec: "Xeno Learning's online coaching service provides students with flexible, personalized coaching that can help them learn to code more effectively and efficiently. The service also offers access to a wide range of coding resources and materials, and provides opportunities for students to interact with other learners and instructors to receive feedback and support.",
    },
    {
      title: "What kind of support can I expect from Xeno Learning's online coaching service?",
      dec: " Xeno Learning's online coaching service provides personalized coaching and feedback on students' coding projects and assignments, as well as access to online discussions and forums to interact with other students and instructors. The service also offers technical support to help students troubleshoot any technical issues that may arise..",
    },
  
  ];
  const [active, setActive] = useState(`a0`);
  const faqMap = (arr, index) => {
    return arr.map((data, i) => (
      <div className="fn_cs_accordion" key={i}>
        <div className={`acc_item ${index + i === active ? "active" : ""}`}>
          <div
            className="acc_header"
            onClick={() =>
              setActive(`${index + i}` === active ? null : `${index + i}`)
            }
          >
            <h3 className="fn__maintitle" data-text={data.title}>
              {data.title}
            </h3>
            <span className="icon">
              <span />
            </span>
          </div>
          <div
            className="acc_content"
            style={{
              display: `${index + i === active ? "block" : "none"}`,
            }}
          >
            <p>{data.dec}</p>
          </div>
        </div>
      </div>
    ));
  };
  return (
    <section id="faq">
      <div className="container">
        <div className="fn_cs_faq">
          <div className="faq_col">
            <h3 className="fn__maintitle" data-text="FAQ"
                data-aos-duration="1000"
            
            data-aos="fade-right"
            >
              FAQ
            </h3>
            <div className="fn_cs_divider">
              <div className="divider">
                <span />
                <span />
              </div>
            </div>
            <div className="desc">
              <p>
              Got questions on XENO Learning, coding online classes?
Check out our FAQ section, where you'll find the answers,
From course content to instructors and pricing plans,
We've got you covered, join us and take a chance.
              </p>
             
            </div>
          </div>
          <div className="faq_col">
            <div className="fn_cs_accordion">{faqMap(faqs1, "a")}</div>
          </div>
          <div className="faq_col">
            <div className="fn_cs_accordion">{faqMap(faqs2, "b")}</div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Faq;
