import ReactPlayer from "react-player";
import { Link, animateScroll as scroll } from "react-scroll";
import { atomOneDark, CopyBlock, shadesOfPurple } from "react-code-blocks";
import sample from "./codeblock"
import "./About.css";
const About = () => {
  return (
    <section id="about">
      {/* About Shortcode */}
      <div className="fn_cs_about">
        <div className="left_part"
                  data-aos="fade-right"
                  data-aos-duration="1000"
        >
          {/* <div className="img">
            <div className="img_in">
              <img
                src={require("../../img/contentimg/XENO LEARNING.jpg")}
                alt=""
              />
            </div>
          </div> */}
          {/* <div className="bg_overlay">
            <div className="bg_color" />
            <div className="bg_image" />
          </div> */}
          <div className="img-codeee">
            <img 
            style={{borderRadius:' 15px'
            }}
            
            src={require('../../img/contentimg/Untitled-4.jpg')} alt="" />
          </div>
        </div>
        <div className="right_part"
              data-aos="fade-left"
              data-aos-duration="1000"
        >
          <div className="right_in">
            <h3 
              data-aos="fade-left"
              data-aos-duration="1000"
            className="fn__maintitle" data-text="At XENO Learning">
              At XENO Learning
            </h3>
            <div className="fn_cs_divider">
              <div className="divider">
                <span />
                <span />
              </div>
            </div>
            <div className="desc">
              <p>
                At XENO Learning, we are passionate about revolutionizing the
                way people learn about technology. We believe that technology
                education should be accessible to everyone, regardless of their
                background or prior experience. That's why we've created a
                comprehensive platform that offers engaging, interactive, and
                personalized learning experiences to students around the world.
              </p>
              <p>
                Our team of experienced educators, developers, and designers are
                committed to creating innovative learning solutions that help
                our students reach their full potential. We use cutting-edge
                technology, including virtual reality and interactive
                simulations, to make learning exciting and effective.
              </p>
              <p>
                Whether you're a beginner just starting your coding journey, or
                an experienced programmer looking to expand your skills, XENO
                Learning has something for everyone. Our curriculum is designed
                to be flexible and adaptable, so you can learn at your own pace
                and on your own schedule.
              </p>
              <p>
                At XENO Learning, we are dedicated to helping our students
                succeed in today's rapidly-changing technology landscape. Join
                us today and discover the power of tech learning!
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* !About Shortcode */}
      <section id="section3">
        <div className="container">
          {/* Mint Shortcode */}

          <div className="fn_cs_mint">
            <div className="left_part">
              <h3
                      data-aos="fade-right"
                      data-aos-duration="1000"
              className="fn__maintitle" data-text="Course Details">
                Course Details
              </h3>
              <div className="fn_cs_divider">
                <div className="divider">
                  <span />
                  <span />
                </div>
              </div>
              <div className="desc">
                <p>
                <div className="title">
                  <h3>
                    <Link>
                      <a>
                      Xeno Learning
                      </a>
                    </Link>
                  </h3>
                </div>
                   offers a variety of coding courses that cater to
                  beginners as well as experienced coders looking to enhance
                  their skills. Here are some of the course details for coding
                  as Xeno Learning
                </p>
                <p>
                <div className="title">
                  <h3>
                    <Link>
                      <a>
                      <span>Programming Fundamentals</span>
                      </a>
                    </Link>
                  </h3>
                </div>
                 
                  This course is designed for beginners who have little or no
                  coding experience. It covers the basics of programming,
                  including variables, data types, control structures, and
                  functions. Students will learn to write code in Python, which
                  is an easy-to-learn and popular programming language.
                </p>
                <p>
                <div className="title">
                  <h3>
                    <Link>
                      <a>
                      <span>Web Development</span>

                      </a>
                    </Link>
                  </h3>
                </div>
                  This course focuses on building websites using HTML, CSS, and
                  JavaScript. Students will learn to create static and dynamic
                  web pages, and build responsive websites that work across
                  different devices. The course also covers popular web
                  development frameworks such as Bootstrap and React.
                </p>
                <p>
                <div className="title">
                  <h3>
                    <Link>
                      <a>
                      <span>
                Data Science
                  </span>

                      </a>
                    </Link>
                  </h3>
                </div>
               This course covers the basics of data science,
                  including data analysis, visualization, and machine learning.
                  Students will learn to work with popular data science
                  libraries such as Pandas and NumPy, and use machine learning
                  algorithms to make predictions and recommendations.
                </p>
                <p>
                  
                <div className="title">
                  <h3>
                    <Link>
                      <a>
                      <span>
             
                 Mobile App Development
            
                  </span>

                      </a>
                    </Link>
                  </h3>
                </div>
                    This course focuses on building mobile
                  apps for Android and iOS platforms. Students will learn to use
                  popular app development frameworks such as React Native and
                  Flutter. The course covers topics such as UI design, app
                  architecture, and app deployment.
                </p>
                <p>
                <div className="title">
                  <h3>
                    <Link>
                      <a>
                      <span>
               Full-Stack Development 
               </span>

                      </a>
                    </Link>
                  </h3>
                </div>
          
               This course covers both front-end and
                  back-end development. Students will learn to build web
                  applications using popular frameworks such as React and
                  Node.js. The course also covers databases, APIs, and
                  deployment to cloud platforms such as AWS and Azure.
                </p>
                <p>
                <div className="title">
                  <h3>
                    <Link>
                      <a>
                      <span>
                  Each course is typically taught
                  </span>

                      </a>
                    </Link>
                  </h3>
                </div>
                
                over a period of several
                  weeks, with a combination of video lectures, coding
                  assignments, and live online sessions with experienced
                  instructors. Students can track their progress and receive
                  feedback on their assignments through Xeno Learning's
                  online platform.
                </p>
              </div>
            </div>
            <div className="right_part">
              {/* Steps Shortcode */}
              <div className="fn_cs_steps">
                <ul>
                  <li>
                    <div className="item">
                      <div className="item_in">
                        <h3 className="fn__gradient_title">01</h3>
                        <p>Graphic Designing</p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="item">
                      <div className="item_in">
                        <h3 className="fn__gradient_title">02</h3>
                        <p>Full Stack Web Development</p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="item">
                      <div className="item_in">
                        <h3 className="fn__gradient_title">03</h3>
                        <p>Coming Soon...</p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="item">
                      <div className="item_in">
                        <h3 className="fn__gradient_title">04</h3>
                        <p>Coming Soon...</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              {/* !Steps Shortcode */}
              {/* Video Shortcode */}
              {/* <div className="fn_cs_video">
              <img src={require('../../img/video/1.jpg')} alt="" />
              <div
                className="popup-youtube"
                href="https://www.youtube.com/embed/7e90gBu4pas"
              >
                <img src={require('../../svg/play.svg')} alt="" className="fn__svg" />
              </div>
            </div> */}
              {/* /Video Shortcode */}
            </div>
          </div>

          {/* !Mint Shortcode */}
        </div>
      </section>
      <section id="about2">
        <div className="container small">
          <div className="fn_cs_shortabout">
            <div className="about_left"
             data-aos-duration="1000"
            
            data-aos="fade-right"
            >
              <h3 className="fn__maintitle" data-text="Xeno: the present reality of diversity and inclusivity, shaping the future.">
              Xeno: the present reality of diversity and inclusivity, shaping the future.
              </h3>
              <div className="fn_cs_divider">
                <div className="divider">
                  <span />
                  <span />
                </div>
              </div>
              <div className="desc">
              <div style={{height: "400px" , overflowY: 'scroll' }}>
      
      <CopyBlock
      text={sample['c']}
      language='c'
      wrapLines
      theme={shadesOfPurple}
    />
    </div>
              </div>
      
            </div>
            <div className="about_right"
             data-aos="fade-left"
             data-aos-duration="1000"
            >
              <div className="abs_img" />
            </div>
          </div>
          {/* <div className="fn_cs_collection_info">
            <h3 className="fn__gradient_title">10,000</h3>
            <h3
              className="fn__maintitle upper"
              data-text="Total Items in Collection"
            >
              Total Items in Collection
            </h3>
            <p>
              There are many variations of passages of lorem ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which {`don't`} look even
              slightly believable.
            </p>
          </div> */}
        </div>
        {/* <div className="fn_cs_video bg">
          <div className="abs_img" />
<div  className=" video-about-b">

          <ReactPlayer
            url={"https://youtu.be/sjkrrmBnpGE"}
           className="v-d1"
            />
            </div>
        </div> */}
        <div className="container">
          {/* Steps Shortcode */}
          <div className="fn_cs_steps gap">
            <ul>
              <li>
                <div className="item">
                  <div className="item_in">
                    <h3 className="fn__gradient_title">01</h3>
                    <p>Graphic Designing</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="item">
                  <div className="item_in">
                    <h3 className="fn__gradient_title">02</h3>
                    <p>Full Stack Web Development</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="item">
                  <div className="item_in">
                    <h3 className="fn__gradient_title">03</h3>
                    <p>Coming Soon...</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="item">
                  <div className="item_in">
                    <h3 className="fn__gradient_title">04</h3>
                    <p>Coming Soon...</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          {/* !Steps Shortcode */}
          <div className="fn_cs_join">
            <div className="join_in">
              <h3
                className="fn__maintitle upper"
                data-text="Join Our Community"
                data-aos="zoom-in"
                data-aos-duration="1000"
              >
                Join Our Community
              </h3>
              <p>
                Join XENO Learning, coding online classes, Where you can learn
                from top instructors with ease, From the basics to the advanced
                topics of code, Our curriculum covers all, let
                your skills unfold.
              </p>

              <div className="buttons">
                <Link to={"section5"}>
                  <span
                    className="metaportal_fn_button"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span>Contact Us</span>
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};
export default About;
